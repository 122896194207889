@import '../../styles/partials/colors';
@import '../../styles/partials/fonts';
@import '../../styles/partials/mixins';
@import '../../styles/partials/global';
@import '../../styles/partials/mixins';

.game__heading {
  background-color: $art-deco-color2;
  padding: 1.25rem;
  font-size: 1.25rem;

  @include tablet {
    font-size: 2.5rem;
  }

  @include desktop {
    font-size: 3rem;
  }
}

.game__subheading {
  font-size: 1rem;
  padding: 1.25rem;
  background-color: $art-deco-accent-color;
}

.terminal-container {
  padding: 1.25rem;
  background-color: $art-deco-background-color;

  @include tablet {
    padding: 0;
  }
}

.terminal-output {
  font-size: 0.8rem;
  color: $art-deco-text-color;
  background-color: $nes-background-color;
  border-radius: 5px;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.5);
  font-weight: bold;
  word-wrap: break-word;

}

.terminal__image {
  width: 50%;
  height: auto;
  margin: 0 auto;
  display: block;
  border-radius: 5px;
  box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.5);
}

.terminal-input {
  font-family: 'Press Start 2P', sans-serif;
  font-size: 1.5rem;
  color: $art-deco-color2;
}

.unmute {
  background-color: $art-deco-text-color;

  font-size: 1.5rem;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.5);

}

@supports (--css:variables) {
  .cmd,
  .terminal,
  .terminal-output,
  .terminal.external [data-text] {
    background-color: var(--background, #000);
  }

  .cmd,
  .cmd div,
  .cmd span[data-text]:not(.cmd-inverted):not(.token):not(.emoji),
  .terminal-output>:not(.raw) a,
  .terminal-output>:not(.raw) div,
  .terminal-output>:not(.raw) span[data-text]:not(.token):not(.inverted):not(.terminal-inverted):not(.cmd-inverted):not(.terminal-error):not(.emoji),
  .terminal.external [data-text] {
    color: var(--font-color, $nes-text-color);
    overflow: hidden;
    font-size: 1rem; 
  }

 
}

.list{
  height: 22vw; 
}

.commands{
  border: 2px solid black; 
  text-align: center; 
  background-color: $art-deco-accent-color;
  @include tablet{
    width: 50%; 
  }

  @include desktop{
    width: 25%; 
  }

  &__heading{
    border-bottom: 2px solid black; 
  }
}