@use "./fonts";
@use "./mixins";

* {
    box-sizing: border-box;
    margin:0;
    padding:0;
    overflow: auto;
}

body{
    font-family: 'Press Start 2P', sans-serif;
}

a{
    text-decoration: none;
}

span{
    text-decoration: none; 
}