@use './fonts' as *;
@use './variables' as *;


@mixin tablet{
    @media screen and (min-width: $tablet) {
        @content; 
    }
}

@mixin desktop{
    @media screen and (min-width: $desktop) {
        @content; 
    }
}

